import React, { FC } from 'react';

import { Layout } from 'layout';
import RelatedProductAndArticle from 'layout/RelatedProductAndArticles/RelatedProductAndArticles';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ArticleComponentProps } from './models';

import './articleComponent.scss';

const ArticleComponent: FC<ArticleComponentProps> = ({
  node: {
    basicInfo,
    body,
    relatedArticle,
    relatedArticlesLabel,
    relatedProducts,
    relatedProductsLabel,
    relatedProductShowMore,
    bibliography,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
  },
}) => {
  const { banner, title } = basicInfo[0];
  const { image } = banner[0];

  return (
    <Layout>
      {seoMetaTitle !== undefined &&
        seoMetaDescription !== undefined &&
        seoMetaKeywords !== undefined && (
          <Seo
            {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
          />
        )}
      {seoMetaTitle !== undefined &&
        seoMetaDescription !== undefined &&
        seoMetaKeywords !== undefined && (
          <PageSchema
            type="WebPage"
            name={seoMetaTitle}
            data={{
              metaTitle: seoMetaTitle,
              metaDescription: seoMetaDescription,
              metaKeywords: seoMetaKeywords,
            }}
          />
        )}
      <div className=" container row article content">
        <div className="">
          <div className="content__title">
            <h1 className="title my-5">{title || ''}</h1>
          </div>
          <div className="display">
            <Image className="mb-5" imageData={image} alt={title || ''} />
            <div className="content__body">
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
            {bibliography ? (
              <>
                <div className="content__bibliography mt-5">{bibliography[0].structure}</div>
                <div
                  className="bibliography__content"
                  dangerouslySetInnerHTML={{ __html: bibliography[0].reference }}
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="container__related--article  mt-5">
          <h3 className="text-center my-5 related__title">{relatedArticlesLabel ?? ''}</h3>
          <div className="container__articles">
            {relatedArticle && relatedArticle.length > 0
              ? relatedArticle.map((article) => (
                  <RelatedProductAndArticle {...article} textButton={relatedProductShowMore} />
                ))
              : null}
          </div>
        </div>
        <div className="container__related--product  mt-5">
          <h3 className="text-center my-5 related__title">{relatedProductsLabel || ''}</h3>
          <div className="container__articles">
            {relatedProducts &&
              relatedProducts.length > 0 &&
              relatedProducts.map((product) => (
                <RelatedProductAndArticle {...product} textButton={relatedProductShowMore} />
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArticleComponent;
