import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ArticleComponent from 'layout/ArticleComponent';

import { ArticlePageProps } from './models';

const ArticlePage: FC<ArticlePageProps> = ({ data: { articlePage } }) => (
  <ArticleComponent node={articlePage} />
);
export const query = graphql`
  query ($url: String) {
    articlePage(url: { eq: $url }) {
      url
      body
      relatedArticlesLabel
      relatedArticle {
        title
        description
        cta {
          name
          url
        }
        image {
          altText
          fallbackUrl
        }
      }
      relatedProductsLabel
      relatedProducts {
        title
        description
        cta {
          name
          url
        }
        image {
          altText
          fallbackUrl
        }
      }
      relatedProductShowMore
      bibliography {
        reference
        structure
      }
      basicInfo {
        summary
        title
        banner {
          image {
            childImageSharp {
              gatsbyImageData(width: 890, height: 500)
            }
          }
        }
      }
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoNoIndex
    }
  }
`;

export default ArticlePage;
